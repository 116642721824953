import request from './ajax'
import qs from 'qs'

// 雇主任务列表
export const getProtectedInfo = (data) => request({
	method: 'get',
	url: '/uc/task/protected',
	params: data
})

// 雇主任务列表
export const loadTaskListOfHirer = (data, token) => request({
    method: 'get',
    url: '/uc/task/list/hirer',
    params: data,
    headers: {
        Authorization: 'Bearer ' + token
    }
})

// 自由制造业者任务列表
export const loadTaskListOfFreelancer = (data, token) => request({
    method: 'get',
    url: '/uc/task/list/freelancer',
    params: data,
    headers: {
        Authorization: 'Bearer ' + token
    }
})

// 任务的周期列表
export const listPeriodsOfTask = (data, token) => request({
		method: 'get',
		url: '/uc/task/periods',
		params: data,
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

// 投标列表
export const listBidListOfTask = (data, token) => request({
		method: 'get',
		url: '/uc/task/bids',
		params: data,
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

// 选标
export const selectBidOfTask = (bidId, token) => request({
		method: 'put',
		url: '/uc/bid/apply/agree/'+bidId,
		data: '',
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

//取消选择
export const cancelSelectBidOfTask = (bidId, token) => request({
	method: 'put',
	url: '/uc/bid/apply/cancelselect/'+bidId,
	data: '',
	headers: {
		Authorization: 'Bearer ' + token
	}
})

//加载一个周期 - 雇主
export const loadPeriod = (periodId, token ) => request({
	method: 'get',
	url: '/uc/task/period/'+periodId,
	data: '',
	headers: {
		Authorization: 'Bearer ' + token
	}
})

//加载一个周期 - 自由职业者
export const loadPeriodForF = (periodId, token ) => request({
	method: 'get',
	url: '/uc/task/infoforf/'+periodId,
	data: '',
	headers: {
		Authorization: 'Bearer ' + token
	}
})

//开始一个周期
export const startPeriod = (periodId ) => request({
	method: 'post',
	url: '/uc/task/period/start/'+periodId
})

//结束一个周期
export const finishPeriod = (periodId ) => request({
	method: 'post',
	url: '/uc/task/period/finish/'+periodId
})

//开始验收流程
export const startCheckPeriod = ( periodId ) => request({
	method: 'post',
	url: '/uc/task/period/startcheck/'+periodId
})

//结束验收流程
export const finishCheckPeriod = ( periodId ) => request({
	method: 'post',
	url: '/uc/task/period/finishcheck/'+periodId
})

//单个工作周期资金托管
export const periodCustodyRecharge = ( data, token ) => request({
	method: 'post',
	url: '/uc/custody/recharge',
	data: qs.stringify(data),
	headers: {
		Authorization: 'Bearer ' + token
	}
})

//查询单个周期资金托管的付款状态
export const queryPeriodCustodyStatus = ( data, token ) => request({
	method: 'get',
	url: '/uc/custody/recharge/query',
	params: data,
	headers: {
		Authorization: 'Bearer ' + token
	}
})

// 加载工作内容列表
export const listWorkItems = (data) => request({
		method: 'get',
		url: '/uc/task/works',
		params: data
	})
// 加载工作内容列表
export const loadWorkItem = ( workItemId, token) => request({
		method: 'get',
		url: '/uc/task/work/'+workItemId,
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

//通过自由职业者和周期，获取一个workitem
export const loadWorkItemByFreelancer = ( data ) => request({
		method: 'get',
		url: '/uc/task/work/freelancer',
		params:data
	})

// 验收工作
export const checkWorkItem = ( data, token) => request({
		method: 'post',
		url: '/uc/task/work/check',
		data: qs.stringify(data),
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

// 批量验收工作
export const checkWorkItemBatch = ( data) => request({
		method: 'post',
		url: '/uc/task/work/checkbatch',
		data: qs.stringify(data)
	})

// 验收全部工作
export const checkWorkItemAll = ( data, token) => request({
		method: 'post',
		url: '/uc/task/work/checkall',
		data: qs.stringify(data),
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

// 踢出某人工作资格
export const kickout = ( workItemId, token) => request({
		method: 'put',
		url: '/uc/task/work/kickout/'+workItemId,
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

// 撤销踢出某人工作资格
export const cancelKickout = ( workItemId, token) => request({
		method: 'put',
		url: '/uc/task/work/cancelkickout/'+workItemId,
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

// 加载日志
export const loadWorkItemLogs = ( workItemId, token) => request({
		method: 'get',
		url: '/uc/task/work/checklogs?work_item_id='+workItemId,
		headers: {
			Authorization: 'Bearer ' + token
		}
	})

// 单个人的资金发放
export const grantSingleWorkItem = ( data, token) => request({
		method: 'post',
		url: '/uc/task/work/grant',
		data : qs.stringify(data),
		headers: {
			Authorization: 'Bearer ' + token
		}
})

// 删除一个任务
export const deleteTask = ( taskId, token) => request({
		method: 'delete',
		url: '/uc/task/delete/'+taskId,
		headers: {
			Authorization: 'Bearer ' + token
		}
})

// 手动开始知道招标
export const startTaskBid = ( taskId, token) => request({
		method: 'get',
		url: '/uc/bid/start/'+taskId,
		headers: {
			Authorization: 'Bearer ' + token
		}
})

// 结束招标
export const endTaskBid = ( taskId, token) => request({
		method: 'put',
		url: '/uc/bid/end/'+taskId,
		headers: {
			Authorization: 'Bearer ' + token
		}
})

// 结束选标
export const endTaskSelectBid = ( taskId, token) => request({
		method: 'put',
		url: '/uc/bid/endselect/'+taskId,
		headers: {
			Authorization: 'Bearer ' + token
		}
})

// 提交工作成果
export const submitWorkItem = ( data ) => request({
	method: 'post',
	url: '/uc/task/work/submit',
	params: data
})

/**
 * 雇主对自由职业者做出评价
 * @param data
 * @returns {*}
 */
export const evaluateToFreelancer = ( data ) => request({
	method: 'post',
	url: '/uc/evaluate/freelancer',
	params: data
})

/**
 * 自由职业者对雇主和任务进行评价
 * @param data
 * @returns {*}
 */
export const evaluateToTask = ( data ) => request({
	method: 'post',
	url: '/uc/evaluate/task',
	params: data
})

