<template>
  <div class="taskList">
    <div class="person-header">
      <p>
        <strong>任务列表</strong>
      </p>
    </div>
    <div class="tasklist-lists">
      <div class="mine-service-bottom">
        <div class="mine-service-bottom-header">
          <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
            <el-tab-pane :label="item.title" :name="item.name" v-for="(item, index) in tabList" :key="index"></el-tab-pane>
          </el-tabs>
          <div class="mine-service-bottom-header-right">
            <i class="iconfont icon-search wh-16 b"></i>
            <input v-model="searchData.kw" type="text" @keydown.enter="loadTaskList()" placeholder="请输入关键字搜索" />
          </div>
        </div>
        <div class="taskListBox" v-loading="listLoading">
          <div class="hirerProject">
            <div class="tasklist-admin-bottom-no-data" v-show="isEmpty">
              <div class="no-data">
                <img src="@/assets/banner/不公开页面.png" style="width:180px;height: auto;"/>
                <p style="text-align: center;color:#9CA3AF">暂无数据</p>
              </div>
            </div>
            <div class="hirerProjects" v-for="(task, index) in taskList" :key="index">
              <div class="hirerProject-center">
                <div class="hirerProject-center-left">
                  <div class="hirerProject-center-left-header">

                    <div class="hirerProject-center-left-header-left">
                      <div v-if="task.certification_type == 0">
                        <i class="iconfont icon-tips r wh-16"></i>
                        <span class="redp">该项目还未设置主体</span>
                      </div>
                      <div v-else>
                        <i v-if="task.certification_type == 1" class="iconfont icon-personal wh-16 b"></i>
                        <i v-if="task.certification_type == 2" class="iconfont icon-hirer wh-16 b"></i>
                        <span class="f-16 h-57"><span style="font-size: 12px;margin-left: 0px;">项目主体：{{ task.certificaion_info.cert_name }}</span></span>
                      </div>
                    </div>

                    <div class="hirerProject-center-left-header-right">
                      <el-tooltip class="item" effect="dark" content="前往任务页" placement="top-end">
                        <span class="f-12 h-57" @click="jumpToFront(task)" style="cursor: pointer;">项目编号：{{ task.sn }}    <i class="el-icon-link b"></i></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <p @click="jumpToFront(task)" style="cursor: pointer;">{{ task.subject }}</p>
                  <ul>
                    <li v-if="task.pay_way == 1">
                      <el-button type="text">
                        <i class="iconfont icon-pay wh-14 b"></i>
                        预算{{ task.budget_amount }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_way == 2">
                      <el-button type="text">
                        <i class="iconfont icon-pay wh-14 b"></i>
                        时薪{{ task.budget_amount }}
                      </el-button>
                    </li>

                    <!--固定薪酬-->
                    <li v-if="task.pay_way == 1">
                      <el-button type="text">
                        <i class="iconfont icon-definite b wh-14"></i>
                        {{ task.pay_way_name }}
                      </el-button>
                    </li>
                    <!--时薪-->
                    <li v-if="task.pay_way == 2">
                      <el-button type="text">
                        <i class="iconfont icon-hour b wh-14"></i>
                        {{ task.pay_way_name }}
                      </el-button>
                    </li>

                    <!--支付周期 周、月、一次性、分期-->
                    <li v-if="task.pay_cycle == 1">
                      <el-button type="text">
                        <i class="iconfont icon-week b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_cycle == 2">
                      <el-button type="text">
                        <i class="iconfont icon-month b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_cycle == 3">
                      <el-button type="text">
                        <i class="iconfont icon-whole b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_cycle == 4">
                      <el-button type="text">
                        <i class="iconfont icon-installment b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>
                    <li>
                      <el-button type="text">
                        <i class="iconfont icon-website b wh-14"></i>
                        {{ task.pay_channel_name }}
                      </el-button>
                    </li>

                    <li v-if="task.work_location == 2">
                      <el-button type="text">
                        <i class="iconfont icon-address b wh-14"></i>
                        工作地点 {{ task.work_region_name }}
                      </el-button>
                    </li>

                    <li v-show="task.audit_status == 1">
                      <el-button type="text">
                        <i class="iconfont icon-time b wh-14"></i>
                        提交时间 {{ task.created_at }}
                      </el-button>
                    </li>
                  </ul>
                </div>

                <div class="tasklist-admin-bottom-font1-right-center">

                  <!--已中标-->
                  <div v-show="task.status < 8 && task.bid_status == 9">
                    <i class="iconfont icon-success"></i>
                    <br />
                    <strong>已中标</strong>
                  </div>

                  <!--已中标-->
                  <div v-show="task.status >= 8 && task.bid_status == 9">
                    <i class="iconfont icon-order-frontpage"></i>
                    <br />
                    <button type="text" class="select" @click="jumpToWorkstation(task)">工作管理</button>
                  </div>

                  <!--竞标中 还没有结果-->
                  <div v-show="( task.status == 4 || task.status == 5 ) && task.bid_type == 1 && task.bid_status == 2 ">
                    <i class="iconfont icon-time"></i>
                    <br />
                    <strong>已投标</strong>
                    <el-popconfirm title="您确定放弃本次投标？放弃后将无法再次参与,请慎重" @confirm="cancelApplyBid(task)">
                      <button type="text" class="select" slot="reference">放弃投标</button>
                    </el-popconfirm>
                  </div>

                  <!--竞标结束后，未中标-->
                  <div v-show="task.status > 5 && task.bid_type == 1 && task.bid_status != 9">
                    <i class="iconfont icon-fail"></i>
                    <br />
                    <strong>未中标</strong>
                  </div>

                  <!--放弃-->
                  <div v-show="task.bid_type == 1 && task.bid_status ==2 ">
                    <i class="iconfont icon-fail"></i>
                    <br />
                    <strong>弃标</strong>
                  </div>

                  <!--拒绝邀请-->
                  <div v-show="task.bid_type == 2 && task.bid_status == 1 ">
                    <i class="iconfont icon-fail"></i>
                    <br />
                    <strong>拒绝邀请</strong>
                  </div>
                </div>
              </div>
              <div class="hirerProject-bottom">
                <p v-show="task.status < 8 && task.bid_status == 9">
                  <i class="iconfont icon-wait g"></i>
                  <strong class="greenp">等待开工</strong>
                  <span class="f-12 h-57">工作将于{{task.work_start_time}}开始，请您关注动态</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block" v-show="pagination.page_num > 1">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.pageSize"
            layout="prev, pager, next,->,total,jumper"
            :total="pagination.total"
            background
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import { loadTaskListOfFreelancer  } from "@/api/taskuc.js"
import { cancel } from "@/api/task.js"

export default {
  data() {
    return {
      isEmpty:false,
      listLoading:false,
      activeName: 'all',
      currentPage: 1,
      tabList: [
        {
          title: '全部项目',
          name: 'all'
        },
        {
          title: '投标中',
          name: 'bid'
        },
        {
          title: '工作中',
          name: 'ing'
        },
        {
          title: '已完结',
          name: 'finish'
        }
      ],
      searchData: {
        kw: '',
        status_group: 'all'
      },
      taskList: [],
      pagination: {
        page: 1,
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    };
  },
  created() {
    this.loadTaskList();
  },
  methods: {
    async loadTaskList() {
      this.listLoading = true;
      const res = await loadTaskListOfFreelancer(this.searchData, this.$root.current_token);
      if (res.statusCode == 200) {
        this.taskList = res.data.list;
        this.pagination = res.data.page;

        if( this.pagination.total == 0 ){
          this.isEmpty = true;
        }else{
          this.isEmpty = false;

        }
      }
      let _this = this;
      setTimeout(function(){
        _this.listLoading = false;
      },800);
    },
    cancelApplyBid( bid ){
      cancel({bid_id:bid.bid_id}, this.$root.current_token).then(res=>{
        if( res.statusCode == 200 ){
          this.loadTaskList()
        }else{
          this.$message.error( res.message )
        }
      }).catch(res=>{})
    },
    jumpToFront( task ){
      window.open('/task/info/' + task.sn,'_blank')
    },
    jumpToWorkstation( task ){ //跳转到工作管理界面
      this.$router.push('/home/freelancer/task/work?sn=' + task.sn );
    },
    handleClick(tab, event) {
      this.searchData.status_group = tab.name;
      this.searchData.page = 1
      this.searchData.kw = ''
      this.loadTaskList();
    },
    handleCommand(command) {
      this.$message('click on item ' + command);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.loadTaskList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.loadTaskList()
    }
  }
};
</script>

<style lang="less" scoped>

.tasklist-admin-bottom-font1-right-center {
  width: 120px;
  margin-left: 39px;
  margin-top:15px;
  text-align: center;
  i {
    display: inline-block;
    font-size: 34px;
    color: #32c864;
  }
  strong {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: #32c864;
    margin-bottom: 20px;
  }
  button {
    text-align: center;
    width: 120px;
    height: 24px;
  }
  .select {
    background-color: #00a2e6;
  }
  .noSelect {
    color: #575757;
    border: 1px solid #b5b5b5;
    background-color: #fff;
  }
}
.tasklist-admin-bottom-no-data {
  background-color: #fff;
  .no-data {
    width: 180px;
    margin: 0 auto;
    font-size: 18px;
    color: #575757;
    padding-top: 200px;
    padding-bottom: 240px;
  }
}

.toRegister {
  width: 940px;
}
.taskList{
  min-height: 940px;
}
.tasklist-back {
  background-color: #f8f8f8;
  height: 60px;
  display: flex;
  align-items: center;

  p {
    height: 24px;
    display: flex;
    align-items: center;
    margin-left: 40px;

    img {
      margin: 0 20px 0 10px;
    }

    span {
      font-size: 12px;
      color: #575757;
    }

    button {
      width: 80px;
      height: 24px;
      background-color: #00a2e6;
      color: #fff;
      font-size: 12px;
      border-radius: 12px;
    }
  }
}

img {
  width: 16px;
  height: 16px;
}

.taskList {
  background-color: #fff;
}

.tasklist-lists {
  background-color: #fff;
  position: relative;
  top: 20px;

  .mine-service-bottom {
    width: 900px;
    padding: 0px 20px;
    margin: 0 auto;

    .mine-service-bottom-header {
      display: flex;
      justify-content: space-between;

      .mine-service-bottom-all-program {
        width: 960px;

        .mine-service-bottom-all-program-center {
          width: 990px;
          display: flex;
          flex-wrap: wrap;
        }
      }

      .mine-service-bottom-header-right {
        height: 30px;

        input {
          width: 200px;
          height: 32px;
          border: 1px solid #b5b5b5;
          text-indent: 10px;

          input[placeholder] {
            color: #474747;
          }
        }

        .icon-search {
          position: relative;
          right: -190px;
          top: 3px;
        }

        /deep/#tab-first {
          width: 120px;
          text-align: center;
        }
      }
    }
  }
}

.hirerProjects {
  width: 900px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 10px;
  .hirerProject-center {
    display: flex;

    .hirerProject-center-left {
      width: 700px;
      border-right: 1px solid #b5b5b5;

      .hirerProject-center-left-header {
        display: flex;
        justify-content: space-between;

        img {
          width: 16px;
          height: 16px;
        }

        .hirerProject-center-left-header-left {
          height: 14px;

          span {
            font-size: 14px;
            margin-left: 10px;
            color:#575757;
          }
        }

        .hirerProject-center-left-header-right {
          margin-right: 40px;
        }
      }

      p {
        font-size: 18px;
        width: 660px;
        margin: 20px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 20px;
      }

      ul {
        display: flex;
        width: 660px;
        flex-wrap: wrap;
        // height: 42px;
        li {
          height: 16px;
          margin-right: 20px;
          margin-bottom: 10px;

          /deep/.el-button {
            font-size: 12px;
            color: #575757;
            padding: 0;
          }
        }
      }
    }

    .hirerProject-center-right {
      width: 200px;

      .hirerProject-center-right-center {
        width: 120px;
        margin: 0 auto;

        button {
          width: 120px;
          height: 24px;
          border-radius: 0;
          margin-bottom: 20px;
        }

        .primary {
          background-color: #00a2e6;
        }

        .danger {
          background-color: #e4007f;
        }
      }
    }
  }

  .hirerProject-bottom {
    width: 900px;
    line-height: 50px;
    background: #f8f8f8;
    margin-top: 10px;
    display: flex;
    border-bottom: 1px solid #ccc;

    p {
      margin-left: 10px;
      display: flex;
      align-items: center;

      strong {
        font-size: 18px;
        margin: 0 0px 0 8px;
      }

      span {
        margin-left: 20px;
      }
    }

    /* .drop {
      margin-top: -120px;
      margin-left: 422px;
    } */
    .yellowp {
      color: #ff9600;
    }

    .redp {
      color: #e4007f;
    }

    .greenp {
      color: #32c864;
    }

    .bluep {
      color: #00a2e6;
    }

    .redbp {
      font-size: 12px;
      color: #e4007f;
    }
  }
}

.block {
  padding: 20px 50%;
}

img {
  width: 16px;
  height: 16px;
}

/deep/.el-tabs {
  width: 620px;
}

/deep/.el-tabs__content {
  width: 900px;
}

/deep/.el-dropdown-link {
  cursor: pointer;
  color: #00a2e6;
  width: 120px;
  text-align: center;
  display: inline-block;
}

/deep/.el-dropdown-menu__item {
  width: 120px;
  text-align: center;
  padding: 0;
}

.box .el-tabs__active-bar.is-top {
  text-align: center;
  width: 0px !important;
  /* 不加important  不会生效，important会覆盖行内样式权限级别*/
  padding: 0 50px !important;
  /* 控制位置*/
  left: 8.5%;
  /* 根据实际情设置，尽量居中就行*/
  box-sizing: border-box;
  background-clip: content-box;
}

/deep/.drop {
  position: relative;
  left: 450px;
}

// dialog的修改
.dialog-center-header {
  width: 520px;
  margin: 0 auto;
  border-bottom: 1px solid #b5b5b5;
}

/deep/.el-dialog__header {
  padding: 0;
}

/deep/.dialog-footer {
  button {
    width: 160px;
    height: 36px;
    background-color: #00a2e6;
  }

  .error {
    background-color: #b5b5b5;
  }

  .dialog-button {
    margin-right: 40px;
  }
}

/deep/.el-dialog__body {
  padding: 0;
}

/deep/.el-dialog--center /deep/ .el-dialog__body {
  padding: 0;
}
</style>
